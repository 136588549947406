.social-style{
    font-family: Oswald !important;
    margin-left: 0 !important;
    color: #525F7F !important;
}
.social-text{
    font-family: Oswald !important;
    color: #C1C1C1 !important;
}
.signin-btn{
    background-color:#12ABC6 !important;
    font-family: Oswald !important;
    font-weight: 300 !important;
    font-style: normal !important;
}
.signup-text{
    font-family: Oswald !important;
    color:#12ABC6 !important
}
.signin-banner{
    font-weight: 700;
    font-size: 2rem;
    color: #32325D;

}
.input-text{
    font-size: 1rem !important;
}
.input-text::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #C1C1C1 !important;
}
.input-text:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #C1C1C1 !important;
    opacity: 1;
}
.input-text::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #C1C1C1 !important; 
    opacity: 1;
}
.input-text:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #C1C1C1 !important;
}

.auth-icons-email{
    font-size: 0.9rem !important;
}
.auth-icons-pass{
    font-size: 0.9rem !important;
}
.remember-me-text{
    font-size: 1rem !important;
}
.social-icons-special{
    margin-top: 1rem;
}