//
// Header
//

.header {
    position: relative;
    .header-title{
        color:$teal;
        font-size: 18px;
        font-weight: 600;
    }
    .header-subtitle{
        color: $gray-700
    }
}
