//
// Base styles
//

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  height: $card-height;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }

  .card-test-counter {
    border-color: $highlight;
    border-width: 0.4rem;
    border-style: solid;
    border-radius: 1rem;
    padding: 3px !important;
    height: 100%;
    width: 100%;
    .test-label-container {
      width: 100%;
      border-radius: 0.5rem;
      p {
        color: $white;
        font-weight: 500;
      }
    }
    .test-label-bg-normal {
      background-color: $optimal;
    }
    .test-label-bg-risk {
      background-color: $negative;
    }
    .test-label-bg-borderline {
      background-color: $borderline;
    }
    .test-label-bg-error {
      background-color: $gray-500;
    }
  }
}

.report-detail-back {
  cursor: pointer;
  i {
    color: $gray-600;
  }
  p {
    color: $gray-600;
  }
}

.not-found {
  .not-found-title{
    color: $highlight;
    font-size: 55px;
  }
}

.report-download-pdf {
  background-color: $highlight;
  border-radius: 1rem;
  padding: 0.5rem !important;
  a {
    font-weight: bold;
    color: $white;
  }
}

.linechart-container{
  .tooltip-apex-container{
    background-color: $white;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    .tooltip-header{
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: $gray-400;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        font-weight: bold;
      }
    }
    .tooltip-body{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      word-wrap: break-word;
      overflow-wrap: break-word;
      max-width: 300px;
      span{
        font-weight: bold;
        font-size: 1.2rem;
      }
      .comment-title{
        margin-top: 10px;
      }
      .comment-subtitle{
        font-size: .8rem;
      }
      .comment-container{
        word-wrap: break-word;
        overflow-wrap: break-word;
        display: flex;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        text-align: center;
        background-color: $gray-200;
        border-radius: .2rem;
        max-width: 300px;
        padding: 5px;
      }
    }
  }
}

.kit-registration-form {
  .kit-registration-form-header {
    background-color: $highlightbg;
    h2 {
      color: $teal;
    }
  }
  .registration-form-input-label {
    color: $gray-700;
  }
  .kit-registration-form-footer {
    .back-button {
      background-color: transparent !important;
      padding: 0;
      margin: 0;
      color: $gray-600 !important;
      font-size: 1rem;
    }
    .next-button {
      border-radius: 1.5rem;
    }
  }
}

.kit-registration-progress {
  overflow: hidden;
  padding-bottom: 3rem;
  .progress-indicator-item {
    background-color: $teal;
    width: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 1.5rem;
    font-weight: bold;
    z-index: 2;
  }
  .progress-indicator-item-inactive {
    background-color: $white;
    width: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-500;
    border-color: $gray-400;
    font-size: 1.5rem;
    font-weight: bold;
    z-index: 2;
    border-style: solid;
    border-width: 0.2rem;
  }
  .progress-indicator-item-text {
    color: $teal;
    font-weight: 600;
    position: absolute;
    margin-top: 5.5rem;
    text-align: center;
  }
  .progress-indicator-item-text-inactive {
    position: absolute;
    color: $gray-500;
    font-weight: 600;
    text-align: center;
    margin-top: 5.5rem;
  }
  .progress-indicator-divider {
    background-color: $teal;
    height: 4px;
    z-index: 1;
    width: 44%;
  }
  .progress-indicator-divider-inactive {
    background-color: $gray-400;
    height: 4px;
    z-index: 1;
    width: 44%;
  }
}

.line-item-pdf {
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
}

.report-detail {
  .card-overall-status {
    border-radius: 0.5rem;
    font-size: 0.8rem;
    color: $white;
  }
  .card-status-risk {
    background-color: $negative;
  }
  .card-status-optimal {
    background-color: $optimal;
  }
  .card-status-borderline {
    background-color: $borderline;
  }
  .card-status-error {
    background-color: $gray-500;
  }
}

.db-card-info-row {
  h5 {
    color: $gray-500;
  }
  .toggler-container {
    display: flex;
    justify-self: center;
    align-items: center;
    padding: 0.5rem;
    border-color: $highlight;
    i {
      color: $highlight;
    }
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  // Workaround for the image size bug in IE
  // See: https://github.com/twbs/bootstrap/pull/28855
  min-height: 1px;
  padding: $card-spacer-x;
  color: $card-color;
}

.card-highlight {
  border-width: 5px !important;
  border-style: solid !important;
  border-color: $highlight !important;
  background-color: transparent;
  .remove-padding {
    padding: 0 !important;
  }
  .blood-summary {
    background-color: transparent !important;
  }
  .chartjs-tooltip {
    background-color: red !important;
  }

  ::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.chart-info {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(183, 242, 255, 1) 100%
  );
  border-top-right-radius: 1.7rem;
  border-bottom-right-radius: 2rem;

  ::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  @media screen and (max-width: 768px) {
    border-top-left-radius: 1.7rem;
    border-bottom-left-radius: 2rem;
  }
  overflow: scroll;
  .chart-info-icon {
    font-size: 1.5rem;
  }
  .bottom-chart-info {
    @media screen and (min-width: 768px) {
      border-top-left-radius: 2rem;
    }
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(183, 242, 255, 1) 100%
    );
  }

  .info-container {
    height: 330px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */
    }

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .categories {
    color: $gray-600;
    font-size: 0.7rem;
  }

  .chart-info-button {
    background-color: $highlight !important;
    padding: 0.5rem 1rem 0.5rem 1rem;
    color: $white;
  }
}

// This is for custom box
.apexcharts-tooltip {
  border-radius: 0.8rem !important;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  // This is for custom header
  &.apexcharts-theme-light .apexcharts-tooltip-title {
    font-family: "quicksand" !important;
    background-color: white !important;
    border-bottom: 0;
    font-weight: bold;
    text-align: center;
    font-size: 0.9rem !important;
  }
  // This is for custom headers
  .apexcharts-tooltip-text-value {
    font-weight: normal;
    font-family: "quicksand" !important;
    font-size: 12px;
  }
}

.apexcharts-yaxis-label {
  fill: #98a1a9 !important;
}
.apexcharts-xaxis-label {
  fill: #98a1a9 !important;
}

.line-chart-container {
  border-color: $gray-400;
  border-style: solid;
  border-width: 2px;
  border-radius: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
}

.stretch-card {
  .input-custom {
    color: $gray-700;
    font-family: "quicksand";
    font-weight: 500;
  }
}

.kit-tracking-panel{
  .kit-header-container{
    .kit-header-kitname{
      color: $gray-600;
    }
  }

  .kit-tracking-progress{
    background-color: $gray-200;
    border-radius: 1rem;
    overflow: hidden;
    .kit-tracking-title-container{
      background-color: $gray-300;
      height: 100% !important;
      i{
        color: $teal;
        font-weight: bold;
        font-size: 1.5rem;
      }
    }
    .icon-blue{
      color: $teal;
      font-size: 1.5rem;
    }
    .icon-gray{
      color: $gray-500;
      font-size: 1.5rem;
    }
  }
}

.risk-panel {
  .risk-icon {
    background-color: $negative;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: $white;
    font-weight: bold;
  }
  .risk-menu {
    cursor: pointer;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    border-width: 1px;
    border-radius: 0.2rem;
    border-style: solid;
    border-color: $primary;
    color: $primary;
  }
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: -$card-spacer-y / 2;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  @include hover() {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius(
      $card-inner-border-radius $card-inner-border-radius 0 0
    );
  }

  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
}

.card-row-rounded-bottom {
  border-radius: calc(2rem - 1px) calc(2rem - 1px) calc(2rem - 1px)
    calc(2rem - 1px) !important;
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(
      0 0 $card-inner-border-radius $card-inner-border-radius
    );
  }
}

//
// Header navs
//

.card-header-tabs {
  margin-right: -$card-spacer-x / 2;
  margin-bottom: -$card-spacer-y;
  margin-left: -$card-spacer-x / 2;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -$card-spacer-x / 2;
  margin-left: -$card-spacer-x / 2;
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0; // For IE: https://github.com/twbs/bootstrap/issues/29396
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
}

.card-img,
.card-img-top {
  @include border-top-radius($card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  @include border-bottom-radius($card-inner-border-radius);
}

// Card deck

.card-deck {
  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}

//
// Card groups
//

.card-group {
  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-right-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-left-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

//
// Accordion
//

.accordion {
  > .card {
    overflow: hidden;

    &:not(:last-of-type) {
      border-bottom: 0;
      @include border-bottom-radius(0);
    }

    &:not(:first-of-type) {
      @include border-top-radius(0);
    }

    > .card-header {
      @include border-radius(0);
      margin-bottom: -$card-border-width;
    }
  }
}
