$max-width-small: 100%;
$max-width-medium: 720px;
$max-width-large: 768px;
$max-width-xlarge: 992px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-xlarge: 1200px;
// Mixins
@mixin responsive-width {
  width: 100%;
  max-width: $max-width-medium;

  @media (min-width: $breakpoint-medium) {
    max-width: $max-width-large;
  }

  @media (min-width: $breakpoint-large) {
    max-width: $max-width-xlarge;
  }

  @media (min-width: $breakpoint-xlarge) {
    max-width: $max-width-xlarge;
  }
}

:root {
  --primary: #f57f32;
  --secondary: #f7fafc;
  --optimal: #10b981;
  --risk: #dc2626;
  --borderline: #ea580c;
  --success: #10b981;
  --info: #3498db;
  --warning: #ea580c;
  --danger: #dc2626;
  --bg-primary: #feefe5;
  --white: #fff;
  --gray-100: #f6f9fc;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #8898aa;
  --gray-700: #525f7f;
  --gray-800: #32325d;
  --gray-900: #212529;
  --black: #000;
}
